import React, {
  Dispatch,
  SetStateAction,
  useEffect,
  useRef,
  useState,
} from 'react'
import { Link } from '~/components'
import { navItems } from '~/data'
import { animateScroll as scroller } from 'react-scroll'
import cn from 'classnames'
import * as st from '~/assets/styl/Nav.module.styl'
import * as st1 from '~/assets/styl/HamburgerMenu.module.styl'
import * as st2 from '~/assets/styl/Hero.module.styl'
import Logo from '~/assets/img/Logo.png'
import { socials } from '~/data'

const instagram = socials.find(({ name }) => name === 'instagram')

interface Props {
  location: Location
  hamburgerOpen: boolean
  setHamburgerOpen: Dispatch<SetStateAction<boolean>>
}

const Nav = ({ location, hamburgerOpen, setHamburgerOpen }: Props) => {
  const [hidden, setHidden] = useState(true)
  useEffect(() => {
    const onScroll = () => setHidden(!window.scrollY)
    onScroll()
    window.addEventListener('scroll', onScroll)
    return () => window.removeEventListener('scroll', onScroll)
  }, [])

  const containerRef = useRef<HTMLDivElement>()
  const [transform, setTransform] = useState<[number, number]>([0, 0])
  const [now, setNow] = useState(Date.now())
  useEffect(() => {
    if (!hamburgerOpen) return

    const onMouseMove = (e: MouseEvent) => {
      let { pageX } = e
      pageX -= containerRef.current.getBoundingClientRect().x
      let position = Math.max(
        Math.min(pageX / containerRef.current.offsetWidth, 1) - 0.5,
        0
      )
      position = position ? position / 0.5 : 0
      const lastLI: HTMLLIElement =
        containerRef.current.querySelector('li:last-child')
      const firstLI: HTMLLIElement =
        containerRef.current.querySelector('li:first-child')
      const listWidth =
        lastLI.offsetLeft - firstLI.offsetLeft + lastLI.offsetWidth
      let offset =
        position * Math.max(listWidth - containerRef.current.offsetWidth, 0)
      offset = offset ? (offset / containerRef.current.offsetWidth) * 100 : 0
      const duration = Math.min(Date.now() - now, 0.4)
      setNow(Date.now())
      setTransform([-offset, duration])
    }
    containerRef.current.addEventListener('mousemove', onMouseMove)

    return () =>
      containerRef.current.removeEventListener('mousemove', onMouseMove)
  }, [hamburgerOpen, containerRef.current, now])

  return (
    <>
      <div
        className={cn(
          st.core,
          hidden &&
            (location.pathname === '/' ||
              location.pathname.startsWith('/empreendimento/') ||
              ['contato', 'corretor', 'sobre'].some(
                (slug) =>
                  location.pathname === '/' + slug ||
                  location.pathname === '/' + slug + '/'
              )) &&
            st.hidden
        )}
        id="menubar"
      >
        <div className={st.container}>
          <Link
            href="/"
            onClick={() =>
              location.pathname === '/' &&
              scroller.scrollTo(0, { duration: 500 })
            }
          >
            <img src={Logo} width="172" height="53" alt="AIKON" />
          </Link>
          <nav
            className={st.nav}
            itemScope
            itemType="http://www.schema.org/SiteNavigationElement"
          >
            <ul>
              {navItems.map(({ name, url, external, outlined }, key) => (
                <li key={key} className={outlined ? st.outlined : null}>
                  <Link href={url} external={external}>
                    {name}
                  </Link>
                </li>
              ))}
            </ul>
          </nav>
          <Link
            href={instagram.href}
            target="_blank"
            rel="noreferrer"
            className="icon-instagram"
          ></Link>
          <button
            onClick={() => setHamburgerOpen(true)}
            className={st.hamburger}
          ></button>
        </div>
      </div>

      <div
        className={cn(st1.core, hamburgerOpen && st1.active)}
        onClick={(e) => {
          if ((e.target as HTMLElement).tagName === 'A') setHamburgerOpen(false)
        }}
      >
        <div>
          <button
            className={cn(st2.hamburger, st1.close)}
            onClick={() => setHamburgerOpen(false)}
          >
            Fechar
          </button>
          <div className={st1.container}>
            <div className={st1.nav} ref={containerRef}>
              <ul
                style={{
                  transform: `translateX(${transform[0]}%)`,
                  transition: `transform ${transform[1]}s ease-out`,
                }}
              >
                {navItems
                  .filter((i) => !i.outlined)
                  .map(({ name, external, url }, key) => (
                    <li key={key}>
                      <Link href={url} external={external}>
                        {name}
                      </Link>
                    </li>
                  ))}
              </ul>
            </div>
            <div className={st1.otherLinks}>
              {navItems
                .filter((i) => i.outlined)
                .map(({ name, external, url }, key) => (
                  <div key={key} className={cn(st.outlined, st1.outlined)}>
                    <Link href={url} external={external}>
                      {name}
                    </Link>
                  </div>
                ))}
              <div className={st1.instagram}>
                <Link
                  href={instagram.href}
                  target="_blank"
                  rel="noreferrer"
                  className="icon-instagram"
                ></Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Nav
