import React from 'react'
import { Link } from '~/components'
import { useMediaQuery } from 'react-responsive'
import { socials, navItems } from '~/data'
import * as st from '~/assets/styl/Footer.module.styl'
import Logo from '~/assets/img/Logo-footer.png'
import Iso from '~/assets/img/certifications/iso.png'
import Iso14 from '~/assets/img/certifications/iso14.png'
import GPTW from '~/assets/img/certifications/gptw.png'
import PBQP from '~/assets/img/certifications/pbqp.png'
import intec from '~/assets/img/certifications/intec.png'

const Socials = () => (
  <ul
    className={st.socials}
    itemScope
    itemType="http://schema.org/Organization"
  >
    {socials.map(({ name, href }, key) => (
      <li key={key}>
        <Link
          href={href}
          external
          className={`icon-${name}`}
          itemProp="sameAs"
        />
      </li>
    ))}
  </ul>
)

const Footer = () => {
  const desktop = useMediaQuery({
    query: '(min-width: 1001px)',
  })

  return (
    <footer className={st.core}>
      <div className={st.container}>
        <div>
          <img
            src={Logo}
            width="300"
            height="92"
            alt="AIKON"
            className={st.logo}
          />
          <div className={st.nav}>
            <ul>
              {navItems
                .filter((i) => !i.outlined)
                .map(({ name, url, subItems }, key) => (
                  <li key={key}>
                    <Link href={url}>{name}</Link>
                    {subItems && (
                      <ul>
                        {subItems.map(({ name, url }, key) => (
                          <li key={key}>
                            <a href={url}>{name}</a>
                          </li>
                        ))}
                      </ul>
                    )}
                  </li>
                ))}
            </ul>
          </div>
        </div>
        <div>
          <div className={st.info}>
            <span className={st.address}>
              Rua Antônio Manoel Moreira, 175 - sala 05
              <br />
              Ed. Porto Januária - Fazenda
              <br />
              CEP 88301-640 - Itajaí/SC
            </span>
            <span>
              {desktop ? (
                <>Comercial - (47) 99286-6003</>
              ) : (
                <a href="tel:47992866003">Comercial - (47) 99286-6003</a>
              )}
              <br />
              {desktop ? (
                <>Compras - (47) 99135-2198</>
              ) : (
                <a href="tel:47991352198">Compras - (47) 99135-2198</a>
              )}
              <br />
              {desktop ? (
                <>RH - (47) 99993-0371</>
              ) : (
                <a href="tel:47999930371">RH - (47) 99993-0371</a>
              )}
            </span>
          </div>
          <div className={st.nav}>
            <div className={st.outlined}>
              {navItems
                .filter((i) => i.outlined)
                .map(({ name, external, url }, key) => (
                  <Link href={url} external={external} key={key}>
                    {name}
                  </Link>
                ))}
            </div>
          </div>
        </div>
        <div>
          <div className={st.certs}>
            <a href="/certificacoes/">
              <img src={Iso} width="89" height="90" alt="ISO 9001" />
            </a>
            <a href="/certificacoes/">
              <img src={Iso14} width="89" height="90" alt="ISO 14001" />
            </a>
            <a href="/certificacoes/">
              <img
                src={GPTW}
                width="53"
                height="90"
                alt="Great Place to Work"
              />
            </a>
            <a href="/certificacoes/">
              <img src={PBQP} width="100" height="70" alt="PBQP-H" />
            </a>
            <a href="/certificacoes/">
              <img src={intec} width="97" height="84" alt="Ranking Intec" />
            </a>
          </div>
          <Socials />
        </div>
        <div>
          <div className={st.tiki}>
            <Link href="https://www.tiki.com.br" external>
              Tiki
            </Link>
          </div>
          <span className={st.copyright}>
            <span>© 2023 Aikon. Todos os direitos reservados.</span>
          </span>
        </div>
      </div>
    </footer>
  )
}

export default Footer
