import React from 'react'
import { Image } from '~/components/ImageSection'
import { useMediaQuery } from 'react-responsive'
import { Button, Link } from '~/components'
import * as st from '~/assets/styl/Enterprise.module.styl'

export interface Props {
  name: string
  slug: string
  resume: string
  neighborhood: string
  city: string
  state: string
  tag: string
  imageFeature: string
  imageSideFirst: string
  imageSideSecond: string
  imageLogo: string
  sold: boolean
}

const Enterprise = ({
  imageFeature: thumbnail,
  imageSideFirst,
  imageSideSecond,
  tag,
  name,
  resume: description,
  city,
  state,
  neighborhood,
  imageLogo: logo,
  slug,
}: Props) => {
  const desktop = useMediaQuery({
    query: '(min-width: 701px)',
  })
  const location = `${neighborhood} - ${city} - ${state}`

  const Content = ({ desktop = false }: { desktop?: boolean }) => (
    <>
      <Image image={thumbnail} />
      <div className={st.description}>
        {!desktop && <p className={st.location}>{location}</p>}
        <span className={st.tag}>{tag}</span>
        <h4
          dangerouslySetInnerHTML={{ __html: name.replace(/\n/g, '<br/>') }}
        ></h4>
        <p>{description}</p>
        {desktop && <p className={st.location}>{location}</p>}
        <Button href={'/empreendimento/' + slug + '/'}>
          Conheça o empreendimento
        </Button>
        <div className={st.logoWrapper}>
          <img width="180" height="180" src={logo} alt={`Logo de ${name}`} />
        </div>
      </div>
      <div className={st.images}>
        <Image image={imageSideFirst} />
        <Image image={imageSideSecond} />
      </div>
    </>
  )

  return desktop ? (
    <div className={st.core}>
      <Content desktop />
    </div>
  ) : (
    <Link href={'/empreendimento/' + slug + '/'} className={st.core}>
      <Content />
    </Link>
  )
}

export default Enterprise
