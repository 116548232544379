import React, { Children, cloneElement, useState, useEffect } from 'react'
import { useMediaQuery } from 'react-responsive'
import cn from 'classnames'
import * as st from '~/assets/styl/Tabs.module.styl'

interface Props {
  children: React.ReactNode
  tabs: Array<string>
  outlined?: boolean
  default?: number
}

const Tabs = ({
  children,
  tabs,
  outlined = false,
  default: _default = 0,
}: Props) => {
  const [active, setActive] = useState(_default)
  useEffect(() => {
    setActive(_default)
  }, [_default])

  const desktop = useMediaQuery({
    query: '(min-width: 601px)',
  })

  return (
    <>
      {tabs.length > 1 && (
        <ul className={cn(st.selector, outlined && st.outlined)}>
          {tabs.map((tab, index) => (
            <li
              className={index === active ? st.active : null}
              onClick={() => setActive(index)}
              key={index}
            >
              {tab}
            </li>
          ))}
          {!outlined && (
            <li
              style={{
                width: desktop
                  ? `${100 / tabs.length}%`
                  : `${
                      active === tabs.length - 1 && active % 2 === 0 ? 100 : 50
                    }%`,
                left: desktop
                  ? `${active * (100 / tabs.length)}%`
                  : `${active % 2 === 0 ? 0 : 50}%`,
              }}
              className={st.bar}
            ></li>
          )}
        </ul>
      )}
      <div className={cn(st.content, 'tabs-content')}>
        {Children.toArray(children)
          .filter((child) => child)
          .map((child: React.ReactElement, key) =>
            cloneElement(child, {
              className: cn(child.props.className, key === active && st.active),
            })
          )}
      </div>
    </>
  )
}

export default Tabs
